<script>
  import screen from '@/helpers/screen'

  export default {
    props: {
      /** Whether the view is to view all orders (viewing all orders will display as modal on desktop and take up full screen on mobile) */
      viewAll: {
        type: Boolean,
        default: false,
      },
      /** If the view is view all on desktop, dismissing the modal should route to here */
      mainRoute: {
        type: [String, Object],
        default: undefined,
      },
      /** This is the route for the view all view */
      viewAllRoute: {
        type: [String, Object],
        default: undefined,
      },
      /** The max number of list items to be displayed on desktop */
      maxDisplayOnDesktop: {
        type: Number,
        default: 2,
      },
      /** The max number of list items to be displayed on mobile */
      maxDisplayOnMobile: {
        type: Number,
        default: 2,
      },
      /** The length of list items */
      listItemsLength: {
        type: Number,
        required: true,
      },
    },
    computed: {
      screen,
    },
  }
</script>

<template>
  <div
    :class="`${
      viewAll ? 'md:mt-4 md:max-h-140 overflow-hidden md:overflow-auto px-6' : 'px-4 md:px-10'
    } shadow-gray-border bg-white py-4 md:py-6`"
  >
    <div v-if="!screen.md && viewAll" class="absolute top-0 mt-5 z-20">
      <BaseLinkStyled
        :to="mainRoute"
        color="black"
        font-size="xs"
        icon-position="left"
        :icon-size="5"
      >
        <template v-slot:icon>
          <IconArrowLeft />
        </template>
      </BaseLinkStyled>
    </div>
    <div
      :class="`${
        viewAll
          ? 'text-lg md:text-xl text-center md:text-left mb-8 md:mb-0'
          : 'text-xl flex justify-between items-center'
      }`"
    >
      <slot name="title" />
      <span
        v-if="
          !viewAll &&
          ((screen.md && listItemsLength > maxDisplayOnDesktop) ||
            (!screen.md && listItemsLength > maxDisplayOnMobile))
        "
        class="text-base"
      >
        <BaseLinkStyled color="black" :to="viewAllRoute">View All</BaseLinkStyled>
      </span>
    </div>
    <ul class="flex flex-wrap">
      <slot name="items" />
    </ul>
  </div>
</template>
