<script>
  import HomeWidgetSingleEntryModal from '@/components/home/HomeWidgetSingleEntryModal'
  import ShippingModal from '@/components/marketplace/ShippingModal'

  export default {
    components: {
      HomeWidgetSingleEntryModal,
      ShippingModal,
    },
    props: {
      order: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        trendingQuestionsEntry: {},
        trendingQuestionsModalVisible: false,
        shippingModalVisible: false,
      }
    },
    computed: {
      splitOrderReference() {
        // split orders have at most one kind of item, qty is separate
        if (this.order.lineItems.length === 1 && this.order.lineItems[0]?.note) {
          // e.g., lorem ipsum ... reference #123-456
          const regex = /reference #(.+)$/
          const found = this.order.lineItems[0]?.note.match(regex)

          if (found) {
            return found[1] // 0 is the whole matched string, 1 is the first capture group
          }
        }

        return null
      },
      trendingQuestionsEntryExists() {
        return !!Object.keys(this.trendingQuestionsEntry).length
      },
      // Does order contain rush shipping fee applied
      orderIsRushShipping() {
        return this.order.rushShipping > 0
      },
      totalNumberOfItems() {
        return this.order.lineItems.reduce((qty, item) => (qty += item.quantity), 0)
      },
      marketReferenceFirstLetter() {
        return this.order.reference?.[0]
      },
      isForMarket() {
        return ['C', 'E', 'P', 'M'].includes(this.marketReferenceFirstLetter)
      },
      marketType() {
        switch (this.marketReferenceFirstLetter) {
          case 'C':
            return 'choicePlus'
          case 'E':
            return 'essentials'
          case 'P':
            return 'upsell'
          default:
            return 'seasonal'
        }
      },
    },
    methods: {
      openTrendingQuestionsModal($event) {
        this.trendingQuestionsEntry = $event
        this.trendingQuestionsModalVisible = true
      },
    },
  }
</script>

<template>
  <article class="flex flex-1 flex-col md:grid md:grid-cols-2 md:gap-4">
    <section>
      <header>Order Details</header>

      <table class="table-auto w-full">
        <tr
          v-for="{ quantity, variant: { id, title, price } } in order.lineItems"
          :key="id"
          class="border-gray-active border-t border-b"
        >
          <td>
            <div>{{ title }}</div>
            <div>{{ quantity }} at {{ $formatPrice(price, false) }} each</div>
          </td>
          <td class="text-right">
            {{ $formatPrice(quantity * price, false) }}
          </td>
        </tr>

        <tr>
          <th>Merchandise</th>
          <td class="font-bold text-right">{{ $formatPrice(order.subtotal, false) }}</td>
        </tr>
        <tr v-if="order.discount">
          <th>Discount</th>
          <td class="text-right">{{ $formatPrice(order.discount, false) }}</td>
        </tr>
        <tr>
          <th>Shipping</th>
          <td class="text-right">{{
            order.shipping ? $formatPrice(order.shipping, false) : 'FREE'
          }}</td>
        </tr>
        <tr>
          <th>Subtotal</th>
          <td class="font-bold text-right">{{
            $formatPrice(order.subtotal + order.discount + order.shipping, false)
          }}</td>
        </tr>
        <tr v-if="order.tax">
          <th>Tax</th>
          <td class="text-right">{{ $formatPrice(order.tax / 100, false) }}</td>
        </tr>
        <tr v-if="order.ecoUpgrade">
          <th>Eco-Upgrade</th>
          <td class="text-right">{{ $formatPrice(order.ecoUpgrade / 100, false) }}</td>
        </tr>
        <tr v-if="order.rushShipping">
          <th>Rush Shipping</th>
          <!-- pennies -->
          <td class="text-right">{{ $formatPrice(order.rushShipping / 100, false) }}</td>
        </tr>
        <tr v-if="order.credit">
          <th>Account Credit</th>
          <td class="text-right">{{ $formatPrice(order.credit / 100, false) }}</td>
        </tr>
        <tr>
          <th>Order Total</th>
          <td class="font-bold text-right">{{ $formatPrice(order.total, false) }}</td>
        </tr>
      </table>
    </section>

    <section>
      <header>Shipping Info</header>

      <p>Shipping Status: {{ order.status }}</p>

      <p>
        {{ order.trackingCarrier ? ' via ' + order.trackingCarrier : '' }}<br />
        <small>
          <a v-if="order.trackingUrl" :href="order.trackingUrl" target="_new">
            {{ order.trackingNumber }}
          </a>
        </small>
      </p>

      <p>
        Shipping To:
        <BaseAddress :address="order.address" />
      </p>
      <BaseDivider class="my-3" color="gray-active" :weight="1" />
      <div>
        <p class="mb-2">
          <span class="text-base font-medium">Shipping Details:</span>
        </p>
        <p v-if="orderIsRushShipping" class="flex items-center">
          <BaseIcon :size="5" class="mt-1 mr-1">
            <IconRushShipping />
          </BaseIcon>
          <span class="text-sm font-semibold mr-1">Holiday Delivery </span>({{ totalNumberOfItems }}
          items)
          <BaseIcon
            type="button"
            label="learn more"
            :size="3"
            class="inline-block align-middle ml-1"
            @click="openTrendingQuestionsModal({ type: 'trending', slug: 'holiday-delivery' })"
          >
            <IconInfoAlertOutline />
          </BaseIcon>
        </p>
        <p v-else class="flex items-center">
          <span class="text-sm font-semibold mr-1">Standard Delivery </span>({{
            totalNumberOfItems
          }}
          items)
          <BaseIcon
            v-if="isForMarket && order.status !== 'Shipped'"
            type="button"
            label="learn more"
            :size="3"
            class="inline-block align-middle ml-1"
            @click="shippingModalVisible = true"
          >
            <IconInfoAlertOutline />
          </BaseIcon>
        </p>
      </div>
      <BaseDivider class="my-3" color="gray-active" :weight="1" />
    </section>

    <section>
      <header>Policies</header>
      <BaseLinkStyled href="//faq.alltrue.com/hc/en-us" target="_blank"
        >Frequently Asked Questions</BaseLinkStyled
      >
    </section>

    <section>
      <header>Order Notes</header>

      <p>Order Number: {{ order.reference }}</p>
      <p>Order Date: {{ $formatDate(order.dateCreated) }}</p>

      <!-- TODO: bring "(<a :href="`/orders/${splitOrderReference}`">{{ splitOrderReference }}</a>)" back when we have a route for invoice -->
      <p v-if="splitOrderReference" class="mt-5">
        NOTE TO CUSTOMER: This order was generated to ship the item(s) listed here separately from
        your original market order to prevent damage during shipping. This may cause the Market
        Orders in your My Account page to reflect a dollar amount total different from that paid at
        time of purchase, but rest assured, no adjustments were made to your original payment
        amount. Please refer to your e-mail receipt from time of purchase for this info.
      </p>
    </section>

    <!-- Shipping Modal -->
    <ShippingModal
      :open="shippingModalVisible"
      :type="marketType"
      @dismiss="shippingModalVisible = false"
    />

    <HomeWidgetSingleEntryModal
      v-if="trendingQuestionsEntryExists"
      :open="trendingQuestionsModalVisible"
      :update="trendingQuestionsEntry"
      override-date="<span class='font-medium'>SHIPPING &amp; DELIVERY</span>"
      @dismiss="trendingQuestionsModalVisible = false"
    />
  </article>
</template>

<style lang="postcss" scoped>
  section {
    @apply py-3;
    @screen md {
      @apply px-3;
    }

    header {
      @apply text-xl mb-2;
    }
  }

  td {
    @apply py-3;
  }
</style>
