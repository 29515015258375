<script>
  import { get } from 'vuex-pathify'
  import screen from '@/helpers/screen'
  import AccountProfileBoxViewAll from '@/components/account/AccountProfileBoxViewAll'
  import AccountProfileOrderInvoice from '@/components/account/AccountProfileOrderInvoice'

  export default {
    components: {
      AccountProfileBoxViewAll,
      AccountProfileOrderInvoice,
    },
    props: {
      /** Whether the view is to view all orders (viewing all orders will display as modal on desktop and take up full screen on mobile) */
      viewAll: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selectedOrder: null,
      }
    },
    computed: {
      ...get('account', ['orders']),
      screen,
      displayedOrders() {
        return this.viewAll
          ? this.orders
          : this.screen.md
          ? this.orders?.slice(0, 6) // eslint-disable-line vue/script-indent
          : this.orders?.slice(0, 3) // eslint-disable-line vue/script-indent
      },
    },
    async mounted() {
      // TODO: add a spinner while loading fresh orders
      // await this.$store.dispatch('account/fetchProfile') // refresh account/orders there are 2 of them
    },
    methods: {
      firstName(order) {
        return order.address?.firstName ?? ''
      },
      lastName(order) {
        return order.address?.lastName ?? ''
      },
    },
  }
</script>

<template>
  <AccountProfileBoxViewAll
    :view-all="viewAll"
    :main-route="{ name: 'AccountProfileOrders' }"
    :view-all-route="{ name: 'AccountProfileOrdersAll' }"
    :list-items-length="orders ? orders.length : 0"
  >
    <template v-slot:title>Order History</template>
    <template v-slot:items>
      <template v-if="!orders">You don't have any orders to display yet</template>
      <template v-else>
        <li
          v-for="(order, index) in displayedOrders"
          :key="index"
          :class="`flex flex-col md:min-w-50 ${viewAll ? 'md:mr-12 md:py-5' : 'mr-14 py-5'}`"
        >
          <div class="font-medium">{{ $formatDate(order.dateCreated) }}</div>
          <div>{{ firstName(order) }} {{ lastName(order) }}</div>
          <div>Total: {{ $formatPrice(order.total) }}</div>
          <div>Status: {{ order.status }}</div>
          <div>
            <BaseLinkStyled @click="selectedOrder = order">View Invoice</BaseLinkStyled>

            <template v-if="order.trackingUrl">
              <span class="mx-2 text-xs">&#x25CF;</span>
              <span>
                <BaseLinkStyled color="black" :href="order.trackingUrl" target="_blank"
                  >Track</BaseLinkStyled
                >
              </span>
            </template>
          </div>
          <BaseDivider v-if="!screen.md && viewAll" class="my-5 w-screen" />
        </li>
      </template>

      <Portal to="modal">
        <BaseModal
          size="lg"
          :open="selectedOrder !== null"
          dismissible
          @dismiss="selectedOrder = null"
        >
          <AccountProfileOrderInvoice :order="selectedOrder" />
        </BaseModal>
      </Portal>
    </template>
  </AccountProfileBoxViewAll>
</template>
