<script>
  import AccountProfileOrderHistory from '@/components/account/AccountProfileOrderHistory'

  export default {
    components: {
      AccountProfileOrderHistory,
    },
  }
</script>

<template>
  <AccountProfileOrderHistory view-all />
</template>
